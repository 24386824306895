import React from 'react'
import '../../../App.css'
import { makeStyles, styled } from '@mui/styles'
import Button, { buttonClasses } from '@mui/material/Button'
import { Mexx2DOConsole } from '../../../utils/mexxTalkConsole'

const GVBRButton = styled(Button)(({ theme }) => {
  Mexx2DOConsole.log("=== GVBRButton ===")
  Mexx2DOConsole.log(theme)
  return ({
  [`&.${buttonClasses.root}`]: {
    '--button-radius': '100em',
    '--button-xsmall': '24px',
    '--button-small': '32px',
    '--button-medium': '40px',
    '--button-large': '48px',
    '--button-size': 'var(--button-medium)',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: '0',
    borderRadius: 'var(--button-radius)',
    color: 'var(--interactive)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '16.8px',
    fontWeight: 'var(--font-weight-semi-bold)',
    height: 'var(--button-size)',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: '0 24px',
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    width: 'auto',
    textTransform: 'none',
    '&:hover': {
      backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))',
    },
  },
  [`&.${buttonClasses.contained}`]: {
    '--interactive-rgb': theme.palette.mode === 'dark' ? 'var(--background-dark-rgb)' : 'var(--color-dark-rgb)',
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--interactive-dark)' : 'var(--interactive-light)',
    color: theme.palette.mode === 'dark' ? 'var(--background-dark)' : 'var(--color-dark)',
    '&:hover': {
      backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))',
      backgroundColor: theme.palette.mode === 'dark' ? 'var(--interactive-dark)' : 'var(--interactive-light)',
    },

  },
  [`&.${buttonClasses.outlined}`]: {
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--background-dark)' : 'var(--background-light)',
    border: '1px solid var(--interactive)',
    '&:hover': {
      backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))',
      backgroundColor: theme.palette.mode === 'dark' ? 'var(--background-dark)' : 'var(--background-light)',
    },
  },
  ...(theme.palette.mode === 'dark' && {
    '--color': 'var(--color-dark)',
    '--color-rgb': 'var(--color-dark-rgb)',
    '--text-color': 'var(--color-dark)',
    '--interactive': 'var(--interactive-dark)',
    '--interactive-rgb': 'var(--interactive-dark-rgb)',
    '--visited': 'var(--visited-dark)',
    '--hover': 'var(--hover-dark)',
    '--pressed': 'var(--pressed-dark)',
    '--focus-color': 'var(--focus-color-dark)',
    '--focus': 'var(--focus-color-dark)',
    color: 'var(--interactive-dark)',
    '&:hover': {
      backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
    },
  }),
  '&:focus': {
    outlineColor: '#C2850C',
    outlineOffset: '4px',
    outlineStyle: 'dashed',
    outlineWidth: '4px'
  }
})})

function GVButton(props) {

  return (<GVBRButton {...props}>
    {props.label}
  </GVBRButton>)
}

export default GVButton;