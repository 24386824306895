import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl, { formControlClasses } from '@mui/material/FormControl';
import Radio, { radioClasses } from '@mui/material/Radio'
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel'
import FormHelperText, { formHelperTextClasses } from '@mui/material/FormHelperText'
import FormLabel, { formLabelClasses } from '@mui/material/FormLabel'
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import '../../App.css'
import './styles.css'
import typography from '../../styles/typography'
import GVFeedback from '../feedback';

const BpIcon = styled('span')(({ theme }) => ({
  background: 'var(--background)',
  border: '1px solid var(--border-color)',
  borderRadius: '50%',
  content: '""',
  height: 'var(--radio-size)',
  left: '0',
  position: 'absolute',
  top: '-1px',
  width: 'var(--radio-size)',
  'input:hover ~ &': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  '&::before': {
    background: 'var(--selected)',
    border: '7px solid var(--selected)',
    borderRadius: '50%',
    content: '""',
    height: 'var(--radio-bullet-size)',
    left: 'var(--radio-bullet-position)',
    position: 'absolute',
    right: '0',
    top: '3px',
    display: 'block',
    width: 'var(--radio-bullet-size)',
  },
  'input:hover ~ &': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  },
});

const BpRadio = styled(Radio)(({ theme }) => ({
  [`&.${radioClasses.root}.Mui-focusVisible span`]: {
    borderColor: 'var(--focus) !important',
    boxShadow: '0 0 0 var(--surface-width-md) var(--focus)',
    outline: 'none',
  }
}));

function GVBRRadio(props) {
  return (
    <BpRadio
      disableRipple
      color="default"
      icon={<BpIcon />}
      checkedIcon={<BpCheckedIcon />}
      {...props}
    />
  );
}

const GVBRFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`&.${formControlLabelClasses.root} `]: {
    marginTop: '8px',
    alignItems: 'flex-start'
  },
  [`& .${formControlLabelClasses.label} `]: {
    ...typography.label,
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0',
    minHeight: 'var(--radio-size)',
    minWidth: 'var(--radio-size)',
    paddingLeft: 'calc(var(--radio-size) / 1.5)',
    position: 'relative',
    fontWeight: 'var(--font-weight-medium)'
  },
  [`& .${formControlLabelClasses.focus} `]: {
    borderColor: 'var(--focus) !important',
    boxShadow: '0 0 0 var(--surface-width-md) var(--focus)',
    outline: 'none',
  }
}))

const GVBRFormControl = styled(FormControl)(({ theme }) => ({
  [`&:focus`]: {
    borderColor: 'var(--focus) !important',
    boxShadow: '0 0 0 var(--surface-width-md) var(--focus)',
    outline: 'none',
  }
}))

const GVBRFormLabel = styled(FormLabel)(({ theme }) => ({
  ...typography.label,
}))

function GVBRRadioButton({ value, name, label, options, isRequired, error, errorMessage, organizationField, changeValue, stateName }) {

  const [radioItem, setRadioItem] = useState(value ? value : 0)

  const handleChange = (fieldType, id, value, stateName) => {
      setRadioItem(value)
    changeValue(fieldType, id, value, stateName)
  }

  return (
    <FormControl
      required={isRequired}
      error={error}
      component="fieldset"
      variant="standard"
      
    >
      <GVBRFormLabel id={`${name}-radio-group`} component="legend">{label}{isRequired && '*'}</GVBRFormLabel>
      <RadioGroup
        aria-labelledby={`${name}-radio-group`}
        name={name}
        value={radioItem}
        onChange={(e) => handleChange(organizationField.organizationfieldtype, organizationField.id, e.target.value, stateName)}
        style={{ paddingLeft: 'calc(var(--spacing-scale-base) / 1.8)' }}>
        {options.map((option) => (
          <GVBRFormControlLabel
            key={option.id}
            control={<GVBRRadio />}
            label={option.name}
            value={option.id} />
        ))}
      </RadioGroup>
      {isRequired && error && (
        <GVFeedback danger>{errorMessage}</GVFeedback>
      )}
    </FormControl>
  );
}

GVBRRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  organizationField: PropTypes.array.isRequired,
  changeValue: PropTypes.func.isRequired,
  stateName: PropTypes.string.isRequired
};

export default GVBRRadioButton;
