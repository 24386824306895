import React from 'react';
import LogoMarca from '../../images/gov-br.png';

const LogoGovBr = props => (
    <div>
        <img style={ props && props.style ? props.style : {}} src={LogoMarca} title="gov.br" />
    </div>
);

export default LogoGovBr;
