import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import './styles.css'
import { styled } from '@mui/styles';

const BreadcrumbLink = styled(Link)(({ theme }) => ({
  maxWidth: '180px',
  overflow: 'hidden',
  textDecoration: 'none',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: 'var(--interactive)',
  cursor: 'pointer',
  padding: '0'
}))

const BreadcrumbTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'var(--font-weight-medium)',
  margin: '0 var(--spacing-scale-2x) 0 var(--spacing-scale-base)',
  whiteSpace: 'nowrap'
}))

export default function IconBreadcrumbs(props) {
  return (
    <div className='br-breadcumb' role="presentation">
      <Breadcrumbs aria-label="breadcrumb" separator="›" >
        <BreadcrumbLink
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="https://www.gov.br/governodigital/pt-br"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </BreadcrumbLink>
        <BreadcrumbLink
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="https://www.gov.br/governodigital/pt-br/atendimento-gov.br"
        >
          Atendimento gov.br
        </BreadcrumbLink>
        <BreadcrumbLink
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="https://www.gov.br/governodigital/pt-br/atendimento-gov.br/duvidas-na-conta-gov.br"
        >
          Dúvidas na gonta gov.br
        </BreadcrumbLink>
        <BreadcrumbLink
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="https://www.gov.br/governodigital/pt-br/atendimento-gov.br/duvidas-na-conta-gov.br/recuperar-conta-gov.br"
        >
          Recuperar conta gov.br
        </BreadcrumbLink>
        <BreadcrumbTypography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          {props.actualPage}
        </BreadcrumbTypography>
      </Breadcrumbs>
    </div>
  );
}