import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CssBaseline, Container, CircularProgress, Grid, Typography, TextField, Button, Paper, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import CustomizedSnackbars from '../../components/material-snackbars'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import Logo from '../../components/logo/logo'
import moment from 'moment'
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole'
import Header from '../../components/header'
import Footer from '../../components/footer'
import LogoGovBr from '../../components/logo/logo-gov-br'
import FormFooter from '../../components/form-footer'

class FormSendErrorValidation extends Component {
  constructor(props) {
    super(props)

    let { location } = this.props

    this.state = {
      errorMessage: location && location.state && location.state.errorMessage ? location.state.errorMessage : '',
      backRoute: location && location.state && location.state.backRoute ? location.state.backRoute : '',
      loading: false,
      notificationVariant: 'success',
      notificationMessage: '',
      openNotification: false
    }

    this.closeNotification = this.closeNotification.bind(this)
  }

  closeNotification = _ => this.setState({ openNotification: false })

  render() {
    const { classes, intl } = this.props;

    return (
      <Grid container spacing={0}>
        <Grid container spacing={0} style={{ backgroundColor: '#F8F8F8', padding: '3% 15%', minHeight: 'calc(100vh - 225px)' }}>
          <Grid container spacing={0}>
            <Grid item sm={12} md={12} lg={12} style={{ width: '100%', boxSizing: 'border-box' }} >
              <Paper elevation={3} style={{ padding: '4% 10%', width: '100%', boxSizing: 'border-box' }}>
                <Grid container spacing={2}>
                  { (this.state.errorMessage && this.state.errorMessage.length > 0) &&
                  <Grid item sm={12} md={12} lg={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Typography variant="body1" className={this.props.class}>
                      <div dangerouslySetInnerHTML={{__html: this.state.errorMessage}} />
                    </Typography>
                  </Grid>}
                  <Grid item sm={12} md={12} lg={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'center' }}>
                    <Button
                      onClick={() => {this.props.history.push({ pathname: `/${this.props.match.params.lang}/${this.state.backRoute.length > 0 ? this.state.backRoute : ''}`})}}
                      variant="contained"
                      color="primary"
                      style={{ color: '#fff', padding: 14, textTransform: 'captalize!important' }} >
                      Enviar outra solicitação
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
          )}

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification} />
          </Grid>
        <Grid item sm={12} md={12} lg={12} >
          <FormFooter />
        </Grid>
      </Grid>
    )
  }
}

FormSendErrorValidation.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(FormSendErrorValidation)));