import React from 'react';
import Image from '../../images/face_recognition_far.jpg';

const FaceRecognitionFar = props => (
    <div style={ props.divStyle != null ? props.divStyle : {} } >
        <img
        alt="Rosto e pescoço dentro da moldura oval: Longe demais"
          src={Image}
          style={ props.style != null ? props.style : {} }
          title="Longe demais" />
    </div>
);

export default FaceRecognitionFar;