import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types' 
import '../../App.css'

export const GVBRTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--color-info, #155bcb)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    alignItems: 'flex-start',
    background: 'var(--color-info, #155bcb)',
    borderRadius: '4px',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    color: 'var(--color-secondary-01, #fff)',
    display: 'flex !important',
    flexWrap: 'wrap',
    fontSize: 'var(--font-size-scale-down-01, 11.662px)',
    fontWeight: 'var(--font-weight-medium, 500)',
    justifyContent: 'flex-start',
    maxWidth: '240px',
    opacity: '1',
    visibility: 'visible',
    padding: 'var(--spacing-scale-2x)',
    position: 'absolute !important',
    zIndex: '999',
  },
}));

export default function GVTooltip({ children, title }) {

  return (<GVBRTooltip title={title}>
    {children}
  </GVBRTooltip>)
}