import React, { useContext } from 'react'
import './styles.css'
import ContrastIcon from '@mui/icons-material/Contrast';
import GVIconButton from '../../icon-button';
import { ThemeContext } from '../../../context/theme-context';
import VwButton from '../../vw-button';

const Header = (props) => {
  const { toggleTheme, theme } = useContext(ThemeContext);

  return (<header className={"br-header" + (theme === 'dark' ? " dark-mode" : "")}>
      <div className="container-lg">
        <div className="header-top">
          <div className="header-logo">
            <a href="https://www.gov.br/pt-br" class="portal-logo">
              <img
                src="https://www.gov.br/++theme++padrao_govbr/img/govbr-logo-large.png" 
                alt="logo"/>
            </a>
              <span className="br-divider vertical"></span>
            <div className="header-sign">
              Ministério da Gestão e da<br/>Inovação em Serviços Públicos
            </div>
          </div>
          <div className="header-actions">
            <div className="header-links dropdown">
              <div className="br-list">
                <div className="header">
                  <div className="title">Acesso Rápido</div>
                </div>
              </div>
            </div><span className="br-divider vertical" style={{ marginLeft: '0.5em', marginRight: '1em' }}></span>
            <div className="header-functions dropdown">
              <div className="br-list">
                <div className="header">
                  <div className="title">Funcionalidades do Sistema</div>
                </div>
                <div className="br-item">
                  <GVIconButton onClick={toggleTheme}>
                    <ContrastIcon style={ theme === 'light' ? { color: 'var(--interactive)' } : { color: 'var(--interactive-dark)' }} />
                  </GVIconButton>
                </div>
                <div className="br-item">
                  <VwButton btnStyle={ theme === 'light' ? { color: 'var(--interactive)' } : { color: 'var(--interactive-dark)' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="header-menu">
            <div className="header-info">
              <div className="header-title">Atendimento gov.br</div>
            </div>
          </div>
        </div>
      </div>
    </header>)
}

export default Header