import React, { useContext } from 'react'
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness'
import {
  defaultDarkModeOverride,
  ThemeProvider
} from '@aws-amplify/ui-react';
import { View, Heading, Alert, Card, Text } from '@aws-amplify/ui-react'
import PropTypes from "prop-types"
import FaceRecognitionNear from '../images/face_recognition_near'
import FaceRecognitionFar from '../images/face_recognition_far'
import './styles.css'
import { ThemeContext } from '../../context/theme-context';

const dictionary = {
	// use default strings for english
	en: null,
	ptbr: {
	  instructionsHeaderHeadingText: 'Verificação de vida',
	  instructionsHeaderBodyText:
		'Você passará por um processo de verificação facial para provar que é uma pessoa real.',
	  instructionListStepOneText:
		'Quando uma moldura oval aparecer, preencha ela com seu rosto durante 7 segundos.',
	  instructionListStepTwoText: 'Maximize o brilho da tela.',
	  instructionListStepThreeText:
		'Certifique-se de que seu rosto não esteja coberto por óculos de sol ou máscara.',
	  instructionListStepFourText:
		'Vá para uma área bem iluminada que não esteja exposta à luz solar direta.',
	  photosensitivyWarningHeadingText: 'Aviso de fotossensibilidade',
	  photosensitivyWarningBodyText:
		'Esta verificação mostra luzes coloridas. Tenha cuidado se você for fotossensível.',
	  instructionListHeadingText:
		'Siga as instruções para concluir a verificação:',
	  goodFitCaptionText: 'Bom encaixe',
	  tooFarCaptionText: 'Longe demais',

	  instructionsBeginCheckText: 'Iniciar leitura do rosto',
	  photosensitivyWarningInfoText:
		'Uma pequena porcentagem de indivíduos pode sofrer ataques epiléticos quando expostos a luzes coloridas. Tenha cuidado se você ou alguém da sua família tiver uma condição epiléptica.',
	  goodFitAltText:
		"Ilustração do rosto de uma pessoa, encaixando-se perfeitamente dentro de um oval.",
	  tooFarAltText:
		"Ilustração do rosto de uma pessoa dentro de um oval; há uma lacuna entre o perímetro da face e os limites do oval.",
	  cameraMinSpecificationsHeadingText:
		'A câmera não atende às especificações mínimas',
	  cameraMinSpecificationsMessageText:
		'A câmera deve suportar pelo menos 320*240 de resolução e 15 quadros por segundo.',
	  cameraNotFoundHeadingText: 'Câmera não acessível.',
	  cameraNotFoundMessageText:
		'Verifique se a câmera está conectada e se as permissões da câmera estão ativadas nas configurações antes de tentar novamente.',
	  retryCameraPermissionsText: 'Tentar novamente',
	  cancelLivenessCheckText: 'Cancelar verificação de vivacidade',
	  recordingIndicatorText: 'gravando...',
	  hintMoveFaceFrontOfCameraText: 'Mova o rosto na frente da câmera',
	  hintTooManyFacesText: 'Certifique-se de que apenas 1(um) rosto esteja na frente da câmera',
	  hintFaceDetectedText: 'Rosto detectado',
	  hintCanNotIdentifyText: 'Mova o rosto na frente da câmera',
	  hintTooCloseText: 'Volte um pouco',
	  hintTooFarText: 'Aproxime-se',
	  hintHoldFacePositionCountdownText: 'Mantenha a posição do rosto durante a contagem regressiva',
	  hintConnectingText: 'Conectando...',
	  hintVerifyingText: 'Verificando...',
	  hintIlluminationTooBrightText: 'Mover para a área mais escura',
	  hintIlluminationTooDarkText: 'Move to brighter area',
	  hintIlluminationNormalText: 'Mover para uma área mais clara',
	  hintHoldFaceForFreshnessText: 'Segure e aguarde firme',
	},
}

function GVCustomFaceLivenessDetector(props) {
  const { theme } = useContext(ThemeContext);

  const livenessTheme = {
    name: 'my-theme',
    overrides: [defaultDarkModeOverride]
  }

  return (<ThemeProvider theme={livenessTheme} colorMode={theme} >
    <FaceLivenessDetector
    sessionId={props.sessionId}
    region="us-east-1"
    onAnalysisComplete={props.handleAnalysisComplete}
    onUserCancel={props.handleUserCancel}
    onError={props.handleError}
    disableInstructionScreen={false}
    displayText={dictionary['ptbr']}
    components={{
      Header: () => {
        return (
          <View flex="1">
            <Text>
              Você passará por um processo de verificação de vida para provar que é uma pessoa real. Será necessário autorizar a utilização da câmera em seu dispositivo para que possamos realizar a <strong>leitura do seu rosto</strong> e do seu <strong>documento oficial com foto</strong>.
            </Text>
            <Text>
              <strong>1. Leitura do rosto:</strong>
            </Text>
          </View>
        )
      },
      PhotosensitiveWarning: () => {
        return (
          <Alert
            variation="warning"
            isDismissible={false}
            hasIcon={true}
            heading="Aviso de fotossensibilidade"
            style={{ color: '#A05D1A!important', backgroundColor: '#F5D9BC!important' }} >
            Esta verificação contém sequências de luzes que podem afetar pessoas fotossensíveis.
          </Alert>
        )
      },
      Instructions: () => {
        return (
          <View flex="1">
            <strong>Siga as instruções abaixo:</strong>
            <ol>
              <li>Esteja em uma área <strong>bem iluminada</strong> que não esteja exposta à luz solar direta;</li>
              <li><strong>Não cubra o rosto</strong> com óculos de sol, máscara ou demais adereços;</li>
              <li><strong>Maximize o brilho da tela</strong> de seu dispositivo para facilitar a leitura do rosto;</li>
              <li>Durante o processo, <strong>posicione o rosto na moldura oval</strong> até o final da contagem.</li>
            </ol>
            Siga o exemplo abaixo:
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <FaceRecognitionNear
                style={{ height: 150 }}
                divStyle={{ marginRight: 5 }} />
              <FaceRecognitionFar
                style={{ height: 150 }}
                divStyle={{ marginLeft: 5 }} />
            </div>
          </View>
        )
        }
    }} />
    </ThemeProvider>)
}

GVCustomFaceLivenessDetector.propTypes = {
  sessionId: PropTypes.string.isRequired,
  handleAnalysisComplete: PropTypes.func.isRequired,
  handleUserCancel: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
}

export default GVCustomFaceLivenessDetector