import { Box, Typography } from '@mui/material'
import React from 'react'
import '../../../App.css'
import '../../../styles/spacing/spacing.css'
import '../../../styles/fonts/font-size.css'
import PropTypes from "prop-types"
import GVButton from '../button'
import GVStepperPannelContent from '../step-pannel-content'
import { useStyles, wizardStyles, GVBRStepper, GVBRStep, GVBRStepButton, GVBRStepConnector, GVBRStepIcon, GVBRStepLabel, StepperPannelFooter } from './component-styles'
import { useFormContext } from 'react-hook-form'

function GVStepper({ handleBack, steps }) {
  const methods = useFormContext()
  const formValues = methods.watch()
  const wizardClasses = wizardStyles()
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [validationErrors, setValidationErrors] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const currentStep = steps[activeStep];
    const { components } = currentStep;

    const stepValidationErrors = {};
    const actualComponent = components.find((item) => {
      if (item.condition && item.condition(formValues))
        return item
    })
    if (actualComponent.completeCondition && !actualComponent.completeCondition(formValues))
      stepValidationErrors[activeStep] = true;

    setValidationErrors(stepValidationErrors);

    if (Object.keys(stepValidationErrors).length === 0) {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    }
  };

  const handleBackToStart = () => {
    setActiveStep(0);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleBackToCaptcha = () => {
    handleBack()
  }

  return (<Box className={wizardClasses.root} sx={{ width: '100%' }}>
      <GVBRStepper className={classes.root} activeStep={activeStep} alternativeLabel connector={<GVBRStepConnector />}>
      {steps.map((step, index) => (
        <GVBRStep key={step.label} completed={completed[index]}>
          <GVBRStepButton color="inherit" onClick={handleStep(index)}>
            <GVBRStepLabel StepIconComponent={GVBRStepIcon} >
              {step.label}
            </GVBRStepLabel>
          </GVBRStepButton>
        </GVBRStep>
      ))}
    </GVBRStepper>
    <GVStepperPannelContent
      steps={steps}
      activeStep={activeStep} />
    <StepperPannelFooter>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <GVButton onClick={handleReset}
                label="Resetar"/>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <GVButton onClick={handleBackToCaptcha}
                label="Voltar ao início"/>
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep > 0 && <GVButton
                variant="outlined"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBackStep}
                sx={{ mr: 1 }}
                label="Voltar"
              />}
              <GVButton onClick={handleComplete}
                variant="contained"
                label={(completedSteps() === totalSteps() - 1 && activeStep == totalSteps() - 1)
                  ? 'Finish'
                  : 'Avançar'}
              />
            </Box>
          </React.Fragment>
        )}
      </StepperPannelFooter>
  </Box>)
}

GVStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      components: PropTypes.arrayOf(
        PropTypes.shape({
          component: PropTypes.elementType.isRequired,
          condition: PropTypes.func.isRequired,
          completeCondition: PropTypes.func.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  handleBack: PropTypes.func.isRequired,
  formSubmit: PropTypes.func
};

export default GVStepper;