import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, RadioGroup } from '@mui/material';
import Radio, { radioClasses } from '@mui/material/Radio'
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel'
import FormHelperText, { formHelperTextClasses } from '@mui/material/FormHelperText'
import FormLabel, { formLabelClasses } from '@mui/material/FormLabel'
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import '../../../App.css'
import './styles.css'
import typography from '../../../styles/typography'
import GVFormHelperText from '../form-helper-text';
import GVFeedback from '../../feedback';

const BpIcon = styled('span')(({ theme }) => ({
  background: 'var(--background)',
  border: '1px solid var(--border-color)',
  borderRadius: '50%',
  content: '""',
  height: 'var(--radio-size)',
  left: '0',
  position: 'absolute',
  top: '-1px',
  width: 'var(--radio-size)',
  'input:hover ~ &': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  },
  /*borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  ,
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },*/
}));

const BpCheckedIcon = styled(BpIcon)({
  '&::before': {
    background: 'var(--selected)',
    border: '7px solid var(--selected)',
    borderRadius: '50%',
    content: '""',
    height: 'var(--radio-bullet-size)',
    left: 'var(--radio-bullet-position)',
    position: 'absolute',
    right: '0',
    top: '3px',
    display: 'block',
    //top: 'calc(var(--radio-bullet-position) - 1px)',
    width: 'var(--radio-bullet-size)',
  },
  'input:hover ~ &': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  },
});

const BpRadio = styled(Radio)(({ theme }) => ({
  
}));

function GVBRRadio(props) {
  return (
    <BpRadio
      disableRipple
      color="default"
      icon={<BpIcon />}
      checkedIcon={<BpCheckedIcon />}
      {...props}
    />
  );
}

const GVBRFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`&.${formControlLabelClasses.root} `]: {
    marginTop: '8px',
    alignItems: 'flex-start'
  },
  [`& .${formControlLabelClasses.label} `]: {
    ...typography.label,
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0',
    minHeight: 'var(--radio-size)',
    minWidth: 'var(--radio-size)',
    paddingLeft: 'calc(var(--radio-size) / 1.5)',
    position: 'relative',
    fontWeight: 'var(--font-weight-medium)'
  }
}))

const GVBRFormLabel = styled(FormLabel)(({ theme }) => ({
  ...typography.label,
}))

function GVRadioButton({ name, label, options, isRequired, errorMessage }) {
  const methods = useFormContext();
  const { control, formState: { errors } } = methods
  const formValues = methods.watch()
  
  return (
    <FormControl
      required={isRequired}
      error={Boolean(errors && errors[name])}
      component="fieldset"
      variant="standard"
    >
      <GVBRFormLabel component="legend">{label}</GVBRFormLabel>
      <Controller
        render={({ field }) => (
          <RadioGroup {...field}>
            {options.map((option) => (
              <GVBRFormControlLabel
                key={option.id}
                control={<GVBRRadio />}
                label={option.name}
                value={option.id}
              />
            ))}
          </RadioGroup>
        )}
        rules={{ required: errorMessage ? errorMessage : 'Selecione uma opção' }}
        name={name}
        control={control}
        defaultValue=""
      />
      {isRequired && errors && errors[name] && (
        <GVFeedback danger>{errors[name].message}</GVFeedback>
      )}
    </FormControl>
  );
}

GVRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.string
};

export default GVRadioButton;
