import DeleteIcon from '@mui/icons-material/Delete'
import { Grid, Typography } from '@mui/material'
import IconButton, { IconButtonClasses, iconButtonClasses } from '@mui/material/IconButton'
import { styled } from '@mui/styles'
import React, { useContext } from 'react'
import { useDropzone } from 'react-dropzone'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { formatBytes } from '../../utils/fileUtils'
import GVTooltip from '../tooltip'
import '../../App.css'
import GVIconButton from '../icon-button'
import './styles.css'
import GVFeedback from '../feedback'
import { ThemeContext } from '../../context/theme-context'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole'


const Dropzone = styled('div')(({ theme }) => {
  return ({
  '--button-radius': '100em',
  '--button-xsmall': '24px',
  '--button-small': '32px',
  '--button-medium': '40px',
  '--button-large': '48px',
  '--button-size': 'var(--button-medium)',
  backgroundColor: 'transparent',
  border: '0',
  borderRadius: 'var(--button-radius)',
  color: theme.palette.mode === 'dark' ? 'var(--interactive-dark)' : 'var(--interactive)',
  cursor: 'pointer',
  display: 'inline-flex',
  fontSize: 'var(--font-size-scale-up-01)',
  fontWeight: 'var(--font-weight-semi-bold)',
  height: 'var(--button-size)',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '0 var(--spacing-scale-3x)',
  position: 'relative',
  textAlign: 'center',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  width: 'auto',
  border: theme.palette.mode === 'dark' ? 'var(--surface-width-sm) dashed var(--interactive-dark)' : 'var(--surface-width-sm) dashed var(--interactive)',
  borderRadius: 'var(--surface-rounder-sm)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontSize: 'var(--font-size-scale-base)',
  fontStyle: 'italic',
  fontWeight: 'var(--font-weight-regular)',
  marginTop: 'var(--spacing-scale-half)',
  maxWidth: '550px',
  minHeight: 'var(--button-size)',
  paddingLeft: 'var(--spacing-scale-2x)',
  paddingRight: 'var(--spacing-scale-2x)',
  textAlign: 'left',
  width: '100%',
  '@media (max-width:575px)': {
    marginTop: 'var(--spacing-scale-baseh)',
    marginRight: 'var(--spacing-scale-2x)',
    borderColor: 'var(--danger)',
    maxWidth: '100%',
  },
  '&:hover': {
    backgroundImage: theme.palette.mode === 'dark' ? 'linear-gradient(rgba(var(--interactive-dark-rgb), var(--hover)), rgba(var(--interactive-dark-rgb), var(--hover)))' : 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  },
  '& svg + p': {
    marginLeft: 'var(--spacing-scale-base)'
  },
  '&:focus-visible': {
    outlineColor: '#C2850C',
    outlineOffset: '4px',
    outlineStyle: 'dashed',
    outlineWidth: '4px'
  }
})})

const ExtendedDropzone = (props) => {

  const { getRootProps, getInputProps } = useDropzone(props);

  return (
    <div>
      <Dropzone style={(props.itemError && props.itemError[`files-${props.itemId}`]) ? { borderColor: 'var(--danger)', display: 'flex' } : { display: 'flex' }} {...getRootProps()}>
        <input {...getInputProps()} />
        <FileUploadIcon />
        <Typography variant="body1" className={props.classStyle}>Arraste e solte ou selecione os arquivos </Typography>
      </Dropzone>
    </div>
  );
};

export default function GVFileUpload({ item, attachmentList, itemError, classes, classStyle, setFileUploadState, removeFilesUploadItems, validateFiles, filesUploadState, saasHirerId }) {
  const { theme } = useContext(ThemeContext)
  const themeU = useTheme();
  const isXsOrSm = useMediaQuery(themeU.breakpoints.between('xs', 'sm'));

  
  Mexx2DOConsole.log(item)  //console.log("=== GVFileUpload ===")
  Mexx2DOConsole.log("item");
  Mexx2DOConsole.log(attachmentList)  //console.log("=== GVFileUpload ===")
  Mexx2DOConsole.log("attachmentList")  //console.log("=== GVFileUpload ===")
    //console.log("=== GVFileUpload ===")
  //console.log("ItemError")
  //console.log(item.id)
  //console.log(itemError[`files-${item.id}`])
  //console.log(itemError)

  return (<Grid key={item.id} item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 24 }}>
    <Grid container
      rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
      columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }} >
      { (item.description && item.description.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12}>
        <div dangerouslySetInnerHTML={ {__html: item.description } }></div>
      </Grid>}
      {attachmentList.map(attachmentItem => {
     
        return (
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <img src={ `${attachmentItem.attachment.url}` } alt={ ((saasHirerId == 15 && (attachmentItem.calledservicesettingsfileuploadid == 1007 || attachmentItem.calledservicesettingsfileuploadid == 1012 ) ) || (saasHirerId == 1011 && (attachmentItem.calledservicesettingsfileuploadid == 1001 || attachmentItem.calledservicesettingsfileuploadid == 1004 )) ) == true ? "Foto frente e verso do documento ou documentos"  : attachmentItem.attachment.description} style={{ maxWidth: '100%' }} />
          </Grid>
        )
      })}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <label for={`${item.label}-input`} style={{ fontWeight: 'bold' }}>
          {item.label}* {item.filelimit > 1 && `(Selecione no máximo ${item.filelimit} anexos)`}:
        </label>
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={8} style={{ paddingTop: 0 }}>
        <Grid container
         rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
         columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }} >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div>
            {item.filelimit > 1 ? 
            <ExtendedDropzone
              id={`${item.label}-input`}
              itemId={item.id}
              itemError={itemError}
              classStyle={classStyle}
              //maxFiles={item.filelimit}
              onDrop={acceptedFiles => validateFiles(item.id, acceptedFiles, item.filelimit)}
              />
            :
            <ExtendedDropzone
              id={`${item.label}-input`}
              itemId={item.id}
              itemError={itemError}
              classStyle={classStyle}
              multiple={false}
              onDrop={acceptedFiles => setFileUploadState(`files-${item.id}`, acceptedFiles)}
              />}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={4} style={isXsOrSm ? { padding: 0 } : {}} />
      { itemError[`files-${item.id}`] && <Grid item xs={12} sm={12} md={10} lg={8} style={{ paddingTop: 0 }}>
        <GVFeedback style={{ maxWidth: 550 }} danger>{itemError[`files-${item.id}-text`]}</GVFeedback>
      </Grid>}
      { itemError[`files-${item.id}`] && <Grid item xs={12} sm={12} md={2} lg={4} />}
      {filesUploadState[`files-${item.id}`] && filesUploadState[`files-${item.id}`].length > 0 && <Grid item xs={12} sm={12} md={10} lg={8}
        className='upload-file-list'>
        <Grid container
          rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
          columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
          className='file-upload-item' >
          {filesUploadState[`files-${item.id}`].map((file, fileIndex) => {
          return (<Grid key={item.id} item xs={12} sm={12} md={12} lg={12} className='file-upload-item-row'>
            <Grid container
              rowSpacing={{ xs: '6px', sm: '12px', md: '24px', lg: '24px', xl: '40px' }}
              columnSpacing={{ xs: '6px', sm: '12px', md: '24px', lg: '24px', xl: '40px' }} >
              <Grid item xs={7} sm={7} md={8} lg={8}
                style={{
                  border: '0',
                  color: theme === 'light' ? '#1351b4' : '#FFF',
                  fontWeight: '400',
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '50%',
                }} className='file-upload-item-column'>
                <GVTooltip title={file.name}>
                  <div style={{
                    color: theme === 'light' ? '#1351b4' : '#FFF',
                    fontWeight: '400',
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>{file.name}</div>
                </GVTooltip>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}
                style={{
                  border: '0',
                  color: theme === 'light' ? 'var(--color)' : 'var(--pure-0)',
                  fontWeight: '400',
                  textAlign: 'left',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }} className='file-upload-item-column'>
                <span>{formatBytes(file.size)}</span>
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={1}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                className='file-upload-item-column'>
                <GVIconButton
                  aria-label="delete-file"
                  onClick={() => { removeFilesUploadItems(`files-${item.id}`, file) }} >
                  <DeleteIcon style={{ color: 'var(--interactive)' }} className={classes.deleteItem} />
                </GVIconButton>
              </Grid>
            </Grid>
          </Grid>)
          })}
        </Grid>
      </Grid>}
    </Grid>
  </Grid>)
}