import React from 'react'
import { useFormContext } from 'react-hook-form'
import GVRadioButton from '../../../../components/gov-br/radio'

function StepGeneralInfo() {
  
  return (<React.Fragment>
    <GVRadioButton
      name="reason"
      label="Qual o motivo da solicitação?"
      isRequired={true}
      options={[
        {
          id: 1,
          name: "Titular da conta não tem mais acesso ao e-mail e telefone de recuperação"
        },
        {
          id: 2,
          name: "Cadastro foi realizado por outra pessoa com consentimento do titular da conta"
        },
        {
          id: 3,
          name: "Suspeita de fraude"
        }
      ]} />
    <GVRadioButton
      name="solicitation"
      label="Para quem é esta solicitação?"
      isRequired={true}
      errorMessage="Uma opção deve ser selecionada para avançar"
      options={[
        {
          id: 1,
          name: "Para mim, eu sou o titular da conta"
        },
        {
          id: 2,
          name: "Para uma criança ou adolescente, sou o responsável"
        },
        {
          id: 3,
          name: "Para um terceiro, sou o representante legal do titular da conta"
        }
      ]} />
  </React.Fragment>)
}

export default StepGeneralInfo;