import React from 'react'
import { IconButton } from "@mui/material"
import { styled } from "@mui/styles"

const GVIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  },
  '&:focus': {
    outlineColor: '#C2850C',
    outlineOffset: '4px',
    outlineStyle: 'dashed',
    outlineWidth: '4px'
  }
}))

export default GVIconButton