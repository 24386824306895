import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CssBaseline, Container, CircularProgress, Grid, Typography, TextField, Button, Paper, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import CustomizedSnackbars from '../../components/material-snackbars'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import Logo from '../../components/logo/logo'
import moment from 'moment'
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole'
import './styles.css'
import Header from '../../components/gov-br/header'
import Footer from '../../components/gov-br/footer'
import GVPaper from '../../components/paper'
import GVButton from '../../components/gov-br/button'
import { ThemeContext } from '../../context/theme-context'
import { biometryValidation } from '../../services/called.service'



class AttendanceSuccessNew extends Component {
  constructor(props) {
    super(props)

    let { location } = this.props

    this.state = {
      calledId: location && location.state && location.state.calledId ? location.state.calledId : 0,
      createdAt: location && location.state && location.state.createdAt ? moment(location.state.createdAt).format('DD/MM/YYYY') : '',
      name: location && location.state && location.state.name ? location.state.name : '',
      email: location && location.state && location.state.email ? location.state.email : '',
      document: location && location.state && location.state.document ? location.state.document : '',
      ofieldsText: location && location.state && location.state.ofieldsText ? location.state.ofieldsText : '',
      backRoute: location && location.state && location.state.backRoute ? location.state.backRoute : '',
      validationofexistingcalls: location && location.state && location.state.validationofexistingcalls ? location.state.validationofexistingcalls : '',
      footerMessage: location && location.state && location.state.footerMessage ? location.state.footerMessage : '',

      loading: false,
      notificationVariant: 'success',
      notificationMessage: '',
      openNotification: false
    }

    Mexx2DOConsole.log("=== AttendanceSuccessNew ===")
    Mexx2DOConsole.log(location && location.state && location.state.ofieldsText ? location.state.ofieldsText : '')

    this.closeNotification = this.closeNotification.bind(this)
  }

  async componentDidMount() {
    await this.biometryVaidation()
  }

  async biometryVaidation() {
    this.setState({ loading: true })

    Mexx2DOConsole.log("this.state")
    Mexx2DOConsole.log(this.state)
    await biometryValidation(this.state.calledId)

    this.setState({ loading: false })
  }

  convertHtmlOption = value => {

    let body = value
    let temp = document.createElement("div")
    temp.innerHTML = body
    let found = null
    for (let i in temp.children) {
      if (temp.children[i].innerHTML && temp.children[i].innerHTML.toString().trim() != '<br>' && temp.children[i].innerHTML.toString().trim().length > 0) {
        found += temp.children[i].innerHTML.toString()
      }
    }
    let sanitized = temp.textContent.trim() || temp.innerText.trim() || found


    return sanitized
  }



  closeNotification = _ => this.setState({ openNotification: false })

  render() {
    const { classes, intl } = this.props;

    return (<div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    }}>
      <CssBaseline />
      <Header />
      <Container component="main" maxWidth="xl" sx={{
        marginTop: 'var(--spacing-scale-3x)!important',
        maxWidth: '100%!important',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
      }}>
        {/* Conteúdo principal */}
        <Grid container
          rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
          columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}>
          <Grid item xs={12} sm={12} md={4} lg={2} >
            <Grid container
              rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
              columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }} >
              <Grid item xs={12} sm={12} md={12} lg={12}>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} />
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Typography variant="p" className={this.props.class} style={{ textAlign: 'right', marginRight: '5%', fontStyle: 'italic', fontWeight: 400 }} >
                  Atendimento ao usuário – Conta GOV.BR.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} style={{ width: '100%', boxSizing: 'border-box' }} >
            <GVPaper elevation={3} style={{ padding: '4% 5.6%', width: '100%', boxSizing: 'border-box', marginBottom: 30 }}>
              <Grid container rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
                columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                  <ThemeContext.Consumer>
                    {({ theme }) => (
                      <Typography variant="h2" className={this.props.class} style={theme === 'light' ? { color: '#1351b4', margin: 0 } : { color: '#FFF', margin: 0 }}>
                        Como podemos ajudar?
                      </Typography>
                    )}
                  </ThemeContext.Consumer>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="p" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    Número do protocolo:&nbsp;
                  </Typography>
                  <Typography variant="p" component="span" className={this.props.class}>
                    {this.state.calledId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="p" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    Data de criação:&nbsp;
                  </Typography>
                  <Typography variant="p" component="span" className={this.props.class}>
                    {this.state.createdAt}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div dangerouslySetInnerHTML={{ __html: this.state.ofieldsText }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="p" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    Nome:&nbsp;
                  </Typography>
                  <Typography variant="p" component="span" className={this.props.class} style={{ wordWrap: 'break-word' }}>
                    {this.state.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="p" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    E-mail:&nbsp;
                  </Typography>
                  <Typography variant="p" component="span" className={this.props.class}>
                    {this.state.email}
                  </Typography>
                </Grid>
                {document.length == 0 && <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="p" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    CPF:&nbsp;
                  </Typography>
                  <Typography variant="p" component="span" className={this.props.class}>
                    {this.state.document}
                  </Typography>
                </Grid>}
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                  <GVPaper elevation={3} >
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={2} md={2} lg={2} style={{ backgroundColor: '#1351b4' }}></Grid>
                      <Grid item xs={12} sm={10} md={10} lg={10} style={{ padding: '25px 25px 25px 20px', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))' }} >
                        <Grid container spacing={0}>
                          {(this.state.footerMessage == null || this.state.footerMessage == "") &&
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="p" className={this.props.class} style={{ fontWeight: 'bold', marginBottom: 0 }}>
                                  IMPORTANTE:
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="p" className={this.props.class} style={{ marginBottom: 0 }}>
                                  Você receberá um e-mail com o número de protocolo de atendimento. E as orientações serão enviadas para o mesmo e-mail no prazo de até 3 dias úteis.
                                </Typography>
                              </Grid>
                            </>
                          }
                          {(this.state.footerMessage != null && this.state.footerMessage != "") &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography variant="p" className={this.props.class} style={{ marginBottom: 0 }}>
                                <div dangerouslySetInnerHTML={{ __html: this.state.footerMessage }} />
                              </Typography>
                            </Grid>
                          }

                        </Grid>
                      </Grid>
                    </Grid>
                  </GVPaper>
                </Grid>
                {!this.state.validationofexistingcalls && <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'center' }}>
                  <GVButton
                    onClick={() => { this.props.history.push({ pathname: `/${this.props.match.params.lang}/${this.state.backRoute.length > 0 ? this.state.backRoute : ''}` }) }}
                    variant="contained"
                    style={{ padding: 14 }}
                    label="Enviar outra solicitação" >
                    Enviar outra solicitação
                  </GVButton>
                </Grid>}
              </Grid>
            </GVPaper>
          </Grid>
        </Grid>
        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification} />
      </Container>
      <Footer />
    </div>)
  }
}

AttendanceSuccessNew.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AttendanceSuccessNew)));