import { createStore,applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import thunk from 'redux-thunk';


const persistConfig = {
    key: "root",
    storage,
    whitelist: ['userSession', 'users', 'forms']
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = applyMiddleware(thunk)( createStore )(persistedReducer);

const persistor = persistStore(store);

export { store, persistor };
