import { combineReducers } from 'redux';
import userSession from './user-session';
import users from './users';
import forms from './form';

export default combineReducers({
    userSession,
    users,
    forms
});
