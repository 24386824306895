import React from 'react';
import PropTypes from 'prop-types';
import styled from '@mui/system/styled';
import Alert, { alertClasses } from '@mui/material/Alert';
import AlertTitle, { alertTitleClasses } from '@mui/material/AlertTitle';
import CancelIcon from '@mui/icons-material/Cancel';import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import '../../App.css'

const getIcon = (danger, success, info, warning) => {
  if (danger) {
    return <CancelIcon fontSize="inherit" />;
  } else if (success) {
    return <CheckCircleIcon fontSize="inherit" />;
  } else if (info) {
    return <InfoIcon fontSize="inherit" />;
  } else if (warning) {
    return <WarningIcon fontSize="inherit" />;
  } else {
    return null;
  }
};

const StyledGVAlertTitle = styled(AlertTitle)(({ theme }) => ({
  padding: '0',
  margin: '0',
  fontSize: '14px'
}))

const StyledGVFeedback = styled(Alert)(({ theme, severity }) => {
  let backgroundColor, color, borderColor;

  if (severity == 'error') {
    backgroundColor = 'var(--danger)';
    color = 'var(--pure-0)';
    borderColor = 'var(--danger)';
  } else if (severity == 'success') {
    backgroundColor = 'var(--success)';
    color = 'var(--pure-0)';
    borderColor = 'var(--success)';
  } else if (severity == 'info') {
    backgroundColor = 'var(--info)';
    color = 'var(--pure-0)';
    borderColor = 'var(--info)';
  } else if (severity == 'warning') {
    backgroundColor = 'var(--warning)';
    color = 'var(--warning-alternative)';
    borderColor = 'var(--warning)';
  } else {
    backgroundColor = theme.palette.background.default;
    color = theme.palette.text.primary;
    borderColor = theme.palette.grey[500];
  }

  return {
    backgroundColor: backgroundColor,
    color: color,
    padding: 4,
    marginTop: 4,
    borderRadius: 0,
    fontStyle: 'italic',
    fontWeight: 'var(--font-weight-medium)',
    [`& .${alertClasses.icon}`]: {
      padding: 0,
      color: color,
      fill: color
    },
    [`& .${alertClasses.message}`]: {
      padding: 0
    },
  };
});

const GVFeedback = ({ danger, success, info, warning, children, style }) => {
  const icon = getIcon(danger, success, info, warning);

  return (
    <StyledGVFeedback icon={icon} style={style} severity={danger ? 'error' : (success ? 'success' : (info ? 'info' : (warning ? 'warning' : 'info')))}>
      <StyledGVAlertTitle>{children}</StyledGVAlertTitle>
    </StyledGVFeedback>
  );
};

GVFeedback.propTypes = {
  danger: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default GVFeedback;